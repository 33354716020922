export const fetchAllContaminantsData = async (sector: string, contaminantType: string): Promise<any> => {
    try {
        const response = await fetch(`/api/data/contaminants/all?sector=${encodeURIComponent(sector)}&contaminant_type=${encodeURIComponent(contaminantType)}`);
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        return await response.json();
    } catch (error) {
        console.error('Error fetching contaminants data:', error);
        throw error;
    }
};

export const fetchExceedanceData = async (): Promise<any> => {
    try {
        const response = await fetch('/api/data/sewage/all');
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        return await response.json();
    } catch (error) {
        console.error('Error fetching exceedance data:', error);
        throw new Error('Error fetching exceedance data');
    }
};
