import React, {useState} from 'react';
import AddNote from './components/AddNote';
import NoteList from './components/NoteList';
import CurrentDate from './components/CurrentDate';
import useLocalStorage from 'use-local-storage';

const App: React.FC = () => {
    const [buttonPressed, setButtonPressed] = useState(0)

    const buttonWasPressed = () => {
        setButtonPressed(buttonPressed + 1)
    }

    // check new users for dark/light mode preference
    const defaultDark = window.matchMedia('(prefers-color-scheme: dark)').matches;
    const [colourScheme, setColourScheme] = useLocalStorage('colourScheme', defaultDark ? 'dark' : 'light');

    // persist user's chosen background image
    const [backgroundImage, setBackgroundImage] = useLocalStorage('backgroundImage', "");

    // set theme based off of user dropdown selection
    const switchTheme = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const newColourScheme = event.target.value;
        setColourScheme(newColourScheme);
    };

    // set background image based off of user file selection
    const pickBackgroundImage = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setBackgroundImage(reader.result as string);
            };
            reader.readAsDataURL(file);
        }
    };

    // remove background image if one was previously set
    const removeBackground = () => {
        setBackgroundImage("");
    }

    return (
        <div className="App" data-theme={colourScheme} style={{backgroundImage: `url(${backgroundImage})`}}>
            <div>
                <h1>TODO Notes Application</h1>
                <CurrentDate />
                <div>
                    <label htmlFor='colourScheme'>Pick a colour scheme: </label>
                    <select id="colourScheme" value={colourScheme} onChange={switchTheme}>
                        <option value="light">Light</option>
                        <option value="dark">Dark</option>
                        <option value="cool">Cool</option>
                        <option value="warm">Warm</option>
                    </select>  
                </div>
                <div>
                    <label>Choose a background image:</label>
                    <br/>
                    <input
                        type="file"
                        accept="image/*"
                        onChange={pickBackgroundImage}
                    />
                    <button id="colourScheme" onClick={removeBackground}>
                        Remove background
                    </button>
                </div>
            </div>       
            <AddNote onPress={buttonWasPressed}/>
            <NoteList buttonPressed={buttonPressed}/>
        </div>
    );
};

export default App;
