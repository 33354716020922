import React, { useEffect, useState } from 'react';
import { getAllNotes } from '../services/noteService';
import { countNotes } from '../services/noteService';
import { Note } from '../types/Note';

interface NoteListProps {
    buttonPressed: number
}
  
const NoteList: React.FC<NoteListProps> = ({ buttonPressed }) => {

    const [notes, setNotes] = useState<Note[]>([]);
    const [itemCount, setItemCount] = useState(0);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<string | null>(null);

    useEffect(() => {
        const fetchNotes = async () => {
            try {
                const notes = await getAllNotes();
                const itemCount = await countNotes();
                console.log('Fetched', itemCount, 'notes:', notes); // Log the fetched notes
                if (Array.isArray(notes)) {
                    setNotes(notes);
                    setItemCount(itemCount);
                } else {
                    setError('Failed to fetch notes');
                }
            } catch (err) {
                console.error('Error fetching notes:', err);
                setError('Failed to fetch notes');
            } finally {
                setLoading(false);
            }
        };
        fetchNotes();
    }, [buttonPressed]);

    if (loading) return <p>Loading...</p>;
    if (error) return <p>{error}</p>;

    return (
        <div>
            <h2>All Notes</h2>
            <p>Total Notes: {itemCount}</p>
            <ul>
                {notes.map(note => (
                    <li key={note.id}>
                        <p>{note.text}</p>
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default NoteList;
