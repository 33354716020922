import React, { useState } from 'react';
import { addNote } from '../services/noteService';
import { Note } from '../types/Note';

interface AddNoteProps {
    onPress: () => void
}

const AddNote: React.FC<AddNoteProps> = ({ onPress }) => {
    const [text, setText] = useState('');

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        const newNote: Omit<Note, 'id'> = { text };
        await addNote(newNote);
        setText('');
        onPress();
    };

    return (
        <form onSubmit={handleSubmit}>
            <div>
                <label>Text</label>
                <textarea value={text} onChange={(e) => setText(e.target.value)} />
            </div>
            <button type="submit">Add Note</button>
        </form>
    );
};

export default AddNote;
