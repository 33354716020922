import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { fetchAllContaminantsData } from '../services/dataService';
import './ValueTable.css';

interface ContaminantsData {
    id: number;
    sector: string;
    facility_name: string;
    sample_date: string;
    contaminant: string;
    reported_as: string;
    component_type: string;
    value: number;
    units: string;
}

const ValueTable: React.FC = () => {
    const { sector, contaminant_type } = useParams<{ sector: string; contaminant_type: string }>();
    const [data, setData] = useState<ContaminantsData[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const rowsPerPage = 15;

    useEffect(() => {
        const getData = async () => {
            if (!sector || !contaminant_type) {
                setError('Invalid URL parameters.');
                setLoading(false);
                return;
            }

            try {
                const result = await fetchAllContaminantsData(sector, contaminant_type);
                setData(result);
            } catch (err) {
                setError('Failed to fetch data');
            } finally {
                setLoading(false);
            }
        };

        getData();
    }, [sector, contaminant_type]);

    // Calculate the data to be displayed on the current page
    const indexOfLastRow = currentPage * rowsPerPage;
    const indexOfFirstRow = indexOfLastRow - rowsPerPage;
    const currentRows = data.slice(indexOfFirstRow, indexOfLastRow);

    // Handle page change
    const handleNextPage = () => {
        if (currentPage < Math.ceil(data.length / rowsPerPage)) {
            setCurrentPage(currentPage + 1);
        }
    };

    const handlePreviousPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    if (loading) return <p>Loading...</p>;
    if (error) return <p>{error}</p>;

    return (
        <div className="value-table-page">
            <h2>{sector} - {contaminant_type}</h2>
            <table className="value-table">
                <thead>
                    <tr>
                        <th>ID</th>
                        <th>Sector</th>
                        <th>Facility Name</th>
                        <th>Sample Date</th>
                        <th>Contaminant</th>
                        <th>Reported As</th>
                        <th>Component Type</th>
                        <th>Value</th>
                        <th>Units</th>
                    </tr>
                </thead>
                <tbody>
                    {currentRows.map((sample) => (
                        <tr key={sample.id}>
                            <td>{sample.id}</td>
                            <td>{sample.sector}</td>
                            <td>{sample.facility_name || 'N/A'}</td>
                            <td>{sample.sample_date || 'N/A'}</td>
                            <td>{sample.contaminant}</td>
                            <td>{sample.reported_as || 'N/A'}</td>
                            <td>{sample.component_type || 'N/A'}</td>
                            <td>{sample.value}</td>
                            <td>{sample.units}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
            <div className="pagination">
                <button
                    onClick={handlePreviousPage}
                    disabled={currentPage === 1}>
                    Previous
                </button>
                <span>Page {currentPage} of {Math.ceil(data.length / rowsPerPage)}</span>
                <button
                    onClick={handleNextPage}
                    disabled={currentPage >= Math.ceil(data.length / rowsPerPage)}>
                    Next
                </button>
            </div>
        </div>
    );
};

export default ValueTable;
