// src/components/Sectors.tsx
import React from 'react';
import { Link } from 'react-router-dom';
import './Sectors.css';

const Sectors: React.FC = () => {
    return (
        <div className="sectors-page">
            <h2>Select a Sector</h2>
            <ul className="sector-list">
                <li>
                    <Link to="/sector/organic-chemical-manufacturing-sector/sulphide">
                        Organic Chemical Manufacturing - Sulphide
                    </Link>
                </li>
                <li>
                    <Link to="/sector/miscellaneous-industrial/exceedances/total-suspended-solids">
                        Miscellaneous Industrial - Total Suspended Solids Exceedance
                    </Link>
                </li>
            </ul>
        </div>
    );
};

export default Sectors;
