// src/components/LimitTable.tsx
import React, { useEffect, useState } from 'react';
import { fetchExceedanceData } from '../services/dataService';
import './LimitTable.css';
import { act } from 'react-dom/test-utils';

interface SewageData {
    id: number;
    sector: string;
    contaminant: string;
    facility: string;
    contaminant_limit: number;
    contaminant_unit: string;
    exceedance_start_date: string;
    exceedance_end_date: string;
    num_exceedances: number;
}

const LimitTable: React.FC = () => {
    const [data, setData] = useState<SewageData[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const rowsPerPage = 15;

    // Format sector and contaminant_type to match the display format
    const formattedSector = 'Miscellaneous Industrial';
    const formattedContaminantType = 'TOTAL SUSPENDED SOLIDS';

    useEffect(() => {
        const getData = async () => {
            try {
                const result = await fetchExceedanceData();

                // Filter the result based on the sector and contaminant type
                const filteredData = result.filter(
                    (item: SewageData) =>
                        item.sector.toLowerCase() === formattedSector.toLowerCase() &&
                        item.contaminant.toUpperCase() === formattedContaminantType
                );

                if (filteredData.length > 0) {
                    setData(filteredData);
                } else {
                    setError('No data available for the selected sector and contaminant type.');
                }
            } catch (err) {
                act(() => {
                    setError('Failed to fetch data');
                });
            } finally {
                act(() => {
                    setLoading(false);
                });
            }
        };

        getData();
    }, []);

    // Calculate the data to be displayed on the current page
    const indexOfLastRow = currentPage * rowsPerPage;
    const indexOfFirstRow = indexOfLastRow - rowsPerPage;
    const currentRows = data.slice(indexOfFirstRow, indexOfLastRow);

    // Handle page change
    const handleNextPage = () => {
        if (currentPage < Math.ceil(data.length / rowsPerPage)) {
            setCurrentPage(currentPage + 1);
        }
    };

    const handlePreviousPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    if (loading) return <p>Data is loading, please wait...</p>;
    if (error) return <p>{error}</p>;

    return (
        <div className="limit-table-page">
            <h2>{formattedSector} - {formattedContaminantType} Exceedance Data</h2>
            <table className="limit-table">
                <thead>
                    <tr>
                        <th>ID</th>
                        <th>Sector</th>
                        <th>Contaminant</th>
                        <th>Facility</th>
                        <th>Contaminant Limit</th>
                        <th>Unit</th>
                        <th>Exceedance Start Date</th>
                        <th>Exceedance End Date</th>
                        <th>Number of Exceedances</th>
                    </tr>
                </thead>
                <tbody>
                    {currentRows.map((sample) => (
                        <tr key={sample.id}>
                            <td>{sample.id}</td>
                            <td>{sample.sector}</td>
                            <td>{sample.contaminant}</td>
                            <td>{sample.facility}</td>
                            <td>{sample.contaminant_limit}</td>
                            <td>{sample.contaminant_unit}</td>
                            <td>{sample.exceedance_start_date}</td>
                            <td>{sample.exceedance_end_date}</td>
                            <td>{sample.num_exceedances}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
            <div className="pagination">
                <button
                    onClick={handlePreviousPage}
                    disabled={currentPage === 1}>
                    Previous
                </button>
                <span>Page {currentPage} of {Math.ceil(data.length / rowsPerPage)}</span>
                <button
                    onClick={handleNextPage}
                    disabled={currentPage >= Math.ceil(data.length / rowsPerPage)}>
                    Next
                </button>
            </div>
        </div>
    );
};

export default LimitTable;
