// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* src/components/Navbar.css */
.navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #007bff;
    padding: 10px 20px;
    color: white;
}

.navbar-logo {
    margin: 0;
    font-size: 1.5em;
}

.navbar-links {
    display: flex;
    gap: 15px;
}

.navbar-link {
    color: white;
    text-decoration: none;
    font-size: 1em;
}

.navbar-link:hover {
    text-decoration: underline;
}
`, "",{"version":3,"sources":["webpack://./src/components/Navbar.css"],"names":[],"mappings":"AAAA,8BAA8B;AAC9B;IACI,aAAa;IACb,8BAA8B;IAC9B,mBAAmB;IACnB,yBAAyB;IACzB,kBAAkB;IAClB,YAAY;AAChB;;AAEA;IACI,SAAS;IACT,gBAAgB;AACpB;;AAEA;IACI,aAAa;IACb,SAAS;AACb;;AAEA;IACI,YAAY;IACZ,qBAAqB;IACrB,cAAc;AAClB;;AAEA;IACI,0BAA0B;AAC9B","sourcesContent":["/* src/components/Navbar.css */\n.navbar {\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n    background-color: #007bff;\n    padding: 10px 20px;\n    color: white;\n}\n\n.navbar-logo {\n    margin: 0;\n    font-size: 1.5em;\n}\n\n.navbar-links {\n    display: flex;\n    gap: 15px;\n}\n\n.navbar-link {\n    color: white;\n    text-decoration: none;\n    font-size: 1em;\n}\n\n.navbar-link:hover {\n    text-decoration: underline;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
