import React from 'react';

const CurrentDate: React.FC = () => {

    const options: Intl.DateTimeFormatOptions = { 
        year: 'numeric', 
        month: 'long', 
        day: 'numeric' 
    };

    const currentDate = new Date().toLocaleDateString('en-US', options);

    return <label>Today is {currentDate}</label>;
};

export default CurrentDate;